export const clientPageType = {
  lobby: 0,
  analitycs: 1,
  workstation: 2,
  customers: 3,
  customer: 4,
  policies: 5,
  profile: 6,
  downloads: 7,
  downloadsCompany: 8,
}

export const clientPage = {
  [clientPageType.lobby]: {
    name: 'Lobby',
    value: clientPageType.lobby,
    href: () => '/lobby',
  },
  [clientPageType.analitycs]: {
    name: 'Analitycs',
    value: clientPageType.analitycs,
    href: () => '/analytics',
  },
  [clientPageType.workstation]: {
    name: 'Workstation',
    value: clientPageType.workstation,
    href: () => '/workstation',
  },
  [clientPageType.customers]: {
    name: 'Customers',
    value: clientPageType.customers,
    href: () => '/customers',
  },
  [clientPageType.customer]: {
    name: 'Customer',
    value: clientPageType.customer,
    href: id => `/customers/${id}`,
  },
  [clientPageType.policies]: {
    name: 'Policies',
    value: clientPageType.policies,
    href: () => `/policies`,
  },
  [clientPageType.profile]: {
    name: 'Profile',
    value: clientPageType.profile,
    href: () => `/profile`,
  },
  [clientPageType.downloads]: {
    name: 'Downloads',
    value: clientPageType.downloads,
    href: () => `/profile`,
  },
  [clientPageType.downloadsCompany]: {
    name: 'Company Downloads Page',
    value: clientPageType.downloadsCompany,
    href: id => `/downloads/${id}`,
  },
}
