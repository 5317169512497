import { createPortal } from 'react-dom'
import './ModalBase.scss'

const ModalBase = ({ closeModal, children }) => {
  const closeModalHandler = () => {
    closeModal && closeModal()
  }
  return createPortal(
    <>
      <div className='modal-base-overlay' />
      <div className='modal-base-container'>
        {closeModal && (
          <div className='modal-base-close-btn'>
            <button onClick={closeModalHandler}>{'\u2716'}</button>
          </div>
        )}
        {children}
      </div>
    </>,
    document.body
  )
}

export default ModalBase
