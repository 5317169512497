import { useState } from 'react'
import { useSelector } from 'react-redux'
import ModalNewMessage from '../../components/ModalNewMessage/ModalNewMessage'
import UsersFilters from '../../components/UsersFilters/UsersFilters'
import UsersNotificationsTable from '../../components/UsersNotificationsTable/UsersNotificationsTable'
import BaseScreen from '../../Containers/BaseScreen/BaseScreen'
import { clientMessageType } from '../../types/clientMessage.js'
import './NotificationsScreen.scss'

const NotificationsScreen = () => {
  const { selectedUsers } = useSelector(state => state.downloads)
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [showPopupModal, setShowPopupModal] = useState(false)

  const noUsers = selectedUsers.length === 0

  const showEmailModalhandler = () => {
    !showEmailModal && setShowEmailModal(true)
  }

  const showPopupModalhandler = () => {
    !showPopupModal && setShowPopupModal(true)
  }

  return (
    <>
      <BaseScreen header={'Notifications & Pop-ups'}>
        <div className='notifications-screen'>
          <UsersFilters />
          <div className='notifications-controls'>
            <div className='notifications-controls-btn-container'>
              <button disabled={noUsers} onClick={showEmailModalhandler}>
                Create a new e-mail
              </button>
            </div>

            <div className='notifications-controls-btn-container'>
              <button disabled={noUsers} onClick={showPopupModalhandler}>
                Messages Management
              </button>
            </div>

          </div>
          <div className='users-table-wrapper'>
            <UsersNotificationsTable />
          </div>
        </div>
      </BaseScreen>
      {showEmailModal && <ModalNewMessage closeModal={() => setShowEmailModal(false)} />}
      {showPopupModal && <ModalNewMessage type={clientMessageType.popUp} closeModal={() => setShowPopupModal(false)} />}
    </>
  )
}

export default NotificationsScreen
