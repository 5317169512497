export function getItemFromLocalStorage(itemName) {
  const token = localStorage.getItem(itemName) || ''

  return token
}

export function setItemToLocalStorage(itemName, itemValue) {
  localStorage.setItem(itemName, itemValue)
}

export function removeItemFromLocalStorage(itemName) {
  localStorage.removeItem(itemName)
}
