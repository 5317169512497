import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { createHashHistory } from 'history'
import { setItemToLocalStorage } from '../services/localStorageService'
import store from '../store/store'
import { logout } from '../store/actions/loginActions'
import { delay } from '../utils/delay'
import { getRandomBool, getRandomInt } from '../utils/number'
import { BASE_URL, urlAccount, urlAgencies, urlCompany, urlNotify, urlReports } from './urls'

const baseURL = BASE_URL
const history = createHashHistory()
const getAuthorizationToken = () => `Bearer ${localStorage.getItem('accessToken')}`

const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use(config => {
  config.headers.Authorization = getAuthorizationToken()
  return config
})

function getRefreshToken() {
  return localStorage.getItem('refreshToken')
}

const accountUrl = urlAccount()
const refreshAuthLogic = failedRequest =>
  instance
    .post(`${accountUrl}refresh-token`, '"' + getRefreshToken() + '"', {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(tokenRefreshResponse => {
      //console.log('tokenRefreshResponse', tokenRefreshResponse)
      const {
        data: {
          data: { accessToken, refreshToken },
        },
      } = tokenRefreshResponse

      // console.log('accessToken', accessToken)
      // console.log('refreshToken', refreshToken)

      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('refreshToken', refreshToken)
      failedRequest.response.config.headers['Authorization'] = 'Bearer ' + accessToken
      return Promise.resolve()
    })
    .catch(err => {
      console.log('logout refresh logic')
      store.dispatch(logout())
      history.push('/')
    })
createAuthRefreshInterceptor(instance, refreshAuthLogic)

instance.interceptors.response.use(
  response => {
    return response
  },
  err => {
    return new Promise((resolve, reject) => {
      const originalReq = err.config
      if (err.response.status === 401 && err.config && !err.config._retry) {
        originalReq._retry = true
        // console.log('logout on 401')
        store.dispatch(logout())
        //console.log('logout unauthorized')
      }
    })
  }
)

export const loginRequest = async (email, password) => {
  const {
    data: { data },
  } = await instance({
    url: `${urlAccount()}login`,
    method: 'POST',
    data: {
      email,
      password,
    },
  })
  const { accessToken, id, refreshToken, refreshTokenExpireAt, userId } = data
  setItemToLocalStorage('accessToken', accessToken)
  setItemToLocalStorage('refreshToken', refreshToken)
  setItemToLocalStorage('refreshTokenExpireAt', refreshTokenExpireAt)
  setItemToLocalStorage('userId', userId)
  setItemToLocalStorage('id', id)
  return userId
}

export const getCompaniesRequest = async (agency, phoneNumber, month) => {
  const { data } = await instance({
    url: `${urlReports()}company-reports`,
    method: 'POST',
    data: {
      agency,
      phoneNumber,
      month,
    },
  })
  const { data: companies } = data
  if (!companies) {
    throw new Error(data.errorMessage)
  }
  return companies
}

export const getUserReportsRequest = async agency => {
  const {
    data: { data: reports },
  } = await instance({
    url: `${urlReports()}user-reports`,
    method: 'POST',
    data: JSON.stringify(agency),
  })
  return reports
}

export const getUserDownloadsRequest = async (agency, month) => {
  const {
    data: {
      data: { companyDownloads },
    },
  } = await instance({
    url: `${urlReports()}companies-downloads`,
    method: 'POST',
    data: {
      agency,
      month,
    },
  })
  return companyDownloads
}

export const getUserDownloadsStatsRequest = async (agency, month) => {
  const {
    data: {
      data: { downloadAttempted, downloadNotAttempted },
    },
  } = await instance({
    url: `${urlReports()}download-stat`,
    method: 'POST',
    data: {
      agency,
      month,
    },
  })
  return { downloadAttempted, downloadNotAttempted }
}

export const getUsersRequest = async ({
  companyIds = [],
  subscriptionIds = [],
  agency = '',
  userName = '',
  userPhone = '',
  agencyName = '',
}) => {
  const {
    data: { data: users },
  } = await instance({
    url: `${urlAccount()}users`,
    method: 'POST',
    data: { companyIds, subscriptionIds, agency, userName, userPhone, agencyName },
  })
  return users
}

export const getCompaniesListRequest = async () => {
  const { data } = await instance({
    url: `${urlCompany()}`,
  })
  const { data: companies } = data
  if (!companies) {
    throw new Error(data.errorMessage)
  }
  return companies
}

export const getSubscriptionsListRequest = async () => {
  const { data } = await instance({
    url: `${urlAccount()}subscriptions`,
  })
  return data
}

export const addMessageRequest = async message => {
  const {
    data: { data: success },
  } = await instance({
    url: `${urlNotify()}send-notification`,
    method: 'POST',
    data: message,
  })
  return success
}

export const getReportAgenciesRequest = async report => {
  await delay()
  const agencies = [...Array(getRandomInt(7, 35))].map((_, i) => ({ agencyName: `Agency ${i + 1}`, id: i + 1 }))
  return agencies
}

export const addUserNote = async (userId, text) => {
  const { data } = await instance({
    url: `${urlAccount()}user-message`,
    method: 'POST',
    data: { userId, text },
  })
  return data
}

export const getCompaniesNotesRequest = async () =>
  await instance({
    url: `${urlCompany()}`,
    method: 'GET',
  })

export const addCompanyNoteRequest = async ({ companyId, subject, body }) =>
  await instance({
    url: `${urlCompany()}edit-company-notification`,
    method: 'POST',
    data: { companyId, subject, body },
  })

export const getReportStatusDetails = async (month, reportNumber, reportStatuses) => {
  const { data } = await instance({
    url: `${urlReports()}report-status-details`,
    method: 'POST',
    data: { month, reportNumber, reportStatuses },
  })
  return data
}

export default instance

export const cancelTokens = {}

export const getCancelToken = tokenKey => {
  if (typeof cancelTokens[tokenKey] != typeof undefined) {
    cancelTokens[tokenKey].cancel(`Operation ${tokenKey} was canceled due to new request.`)
  }
  cancelTokens[tokenKey] = axios.CancelToken.source()
  return { cancelToken: cancelTokens[tokenKey].token }
}
