import { NavLink } from 'react-router-dom'
import { routes } from '../../API/routes'
import './Navbar.scss'

const Navbar = () => {
  return (
    <nav className='navbar-main'>
      <ul>
        {routes
          .filter(route => route.navbarItem)
          .map(route => (
            <NavLink to={route.route} key={route.route + route.text}>
              <li>{route.text}</li>
            </NavLink>
          ))}
      </ul>
    </nav>
  )
}

export default Navbar
