import { useState } from 'react'
import { getPolicyTypesRequest, updatePolicyTypeRequest } from '../../API/requests/policyTypesRequests'
import { getMasterCategoriesRequest } from '../../API/requests/sharedRequests'
import PolicyTypesTable from '../../components/PolicyTypesTable/PolicyTypesTable'
import BaseScreen from '../../Containers/BaseScreen/BaseScreen'
import useApiRequest from '../../hooks/useApiRequest'
import './PolicyTypesScreen.scss'

const PolicyTypesScreen = () => {
  const [updateLoading, setUpdateLoading] = useState(false)
  const {
    error,
    data: policyTypes,
    loading: policyTypesLoading,
    fetch: getPolicyTypes,
  } = useApiRequest(getPolicyTypesRequest, { dataInit: [], logError: true })
  const { data: categories, loading: categoriesLoading } = useApiRequest(getMasterCategoriesRequest, { dataInit: [] })

  const updatePolicyType = async (id, categoryId) => {
    try {
      setUpdateLoading(true)
      const {
        data: { data: success },
      } = await updatePolicyTypeRequest(id, categoryId)
      if (success) getPolicyTypes({ silentLoading: true })
      setUpdateLoading(false)
    } catch (error) {
      setUpdateLoading(false)
    }
  }

  return (
    <BaseScreen header={'Policy Types'}>
      <PolicyTypesTable
        policyTypes={policyTypes}
        policyTypesLoading={policyTypesLoading}
        categories={categories}
        categoriesLoading={categoriesLoading || updateLoading}
        updatePolicyType={updatePolicyType}
        error={error}
      />
    </BaseScreen>
  )
}

export default PolicyTypesScreen
