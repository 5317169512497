import { useState } from 'react'
import ModalAddUserNote from '../ModalAddUserNote/ModalAddUserNote'
import './UsersTable.scss'

const UsersTable = ({ users = [], usersLoading = true }) => {
  const [selectedUser, setSelectedUser] = useState(null)

  const pages = 0

  const nextPaymentDateStyle = date => {
    if (!date) return {}
    const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
    const today = new Date()
    const paymentDate = new Date(date)

    const diffDays = Math.round((paymentDate - today) / oneDay)
    if (diffDays <= 0) return { background: 'gray' }
    if (diffDays <= 2) return { background: 'red' }
    if (diffDays <= 7) return { background: 'yellow', color: 'black' }
    return {}
  }

  if (usersLoading) {
    return <div className='users-table-placeholder'>Loading...</div>
  }

  if (users.length === 0) {
    return <div className='users-table-placeholder'>No Users Found</div>
  }

  return (
    <>
      <div className='users-table-container'>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone</th>
              <th>Agency Number</th>
              <th>Agency Name</th>
              <th>Main Agent</th>
              <th>Amount of Customers</th>
              <th>Subscription</th>
              <th>Month Price</th>
              <th>Next Payment</th>
              <th>Email</th>
              <th>Created</th>
              <th>Agreed Terms</th>
            </tr>
          </thead>
          <tbody>
            {users.map(u => (
              <tr key={u.id}>
                <td>
                  <div>{u.fullName}</div>
                  <div>
                    <button style={{ marginBottom: '10px' }} onClick={() => setSelectedUser(u)}>
                      Add Note
                    </button>
                  </div>
                </td>
                <td>{u.phoneNumber}</td>
                <td>
                  {u.agencyNumber ?? '-'}
                  <br />
                  <span style={{ fontSize: '12px', fontStyle: 'italic' }}>{u.agencyDbName ?? '-'}</span>
                </td>
                <td>{u.agencyName ?? '-'}</td>
                <td>{u.mainAgent ?? '-'}</td>
                <td>{u.customersCount ?? '-'}</td>
                <td>{u.subscription ?? '-'}</td>
                <td>{u.price}</td>
                <td style={{ ...nextPaymentDateStyle(u.nextPaymentDate) }}>
                  {u.nextPaymentDate ? new Date(u.nextPaymentDate).toLocaleDateString() : '-'}{' '}
                </td>
                <td title={u.email} style={{ maxWidth: '220px', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  {u.email}
                </td>
                <td>{u.createdDate ? new Date(u.createdDate).toLocaleDateString() : '-'}</td>
                <td>{u.agreedTerms ? '\u2713' : '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {pages > 0 && <div className='users-table-paging'>Pages</div>}
      {selectedUser && <ModalAddUserNote closeModal={() => setSelectedUser(null)} user={selectedUser} />}
    </>
  )
}

export default UsersTable
