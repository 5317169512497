import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addUserNote } from '../../API/http'
import { USERS_ADD_NOTE } from '../../store/actionTypes'
import ModalBase from '../Common/ModalBase/ModalBase'
import './ModalAddUserNote.scss'

const ModalAddUserNote = ({ closeModal, user }) => {
  const [addNoteLoading, setAddNoteLoading] = useState(false)
  const [addNoteError, setAddNoteError] = useState(null)
  const [note, setNote] = useState('')
  const dispatch = useDispatch()

  const changeHandler = e => {
    const { value } = e.target
    setNote(value)
  }
  const submitlHandler = async e => {
    e.preventDefault()
    try {
      setAddNoteLoading(true)
      setAddNoteError(null)
      const response = await addUserNote(user.id, note)
      if (response.hasError) {
        setAddNoteError(response.errorMessage)
        return
      }
      if (!response.data) {
        throw new Error()
      }
      dispatch({ type: USERS_ADD_NOTE, payload: { id: user.id, note } })
      closeModal()
    } catch (error) {
      setAddNoteError('Server Error')
    } finally {
      setAddNoteLoading(false)
    }
  }

  const submitDisabled = note.length === 0 || addNoteLoading

  return (
    <ModalBase closeModal={closeModal}>
      <div className='add-note-modal-content'>
        <div className='modal-base-header'>
          <h3>Add Note about {user.fullName}</h3>
        </div>
        <form action='' onSubmit={submitlHandler}>
          <div className='modal-form-inputs-container'>
            <div className='modal-form-input-container'>
              <textarea
                id='note'
                name='note'
                autoFocus
                value={note}
                placeholder='Note...'
                onChange={changeHandler}
                rows={3}
              />
            </div>
          </div>
          <div className='modal-form-actions-container'>
            <input type='submit' value='Send' onSubmit={submitlHandler} disabled={submitDisabled} />
          </div>
        </form>
      </div>
      {user.popupMessage.length !== 0 && (
        <>
          <div>Note previously added:</div>
          <div style={{ fontStyle: 'italic' }}>{user.popupMessage}</div>
        </>
      )}
    </ModalBase>
  )
}

export default ModalAddUserNote
