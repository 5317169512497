import { useEffect, useState } from 'react'
import BaseScreen from '../../Containers/BaseScreen/BaseScreen'
import DeletedAgenciesTable from '../../components/DeletedAgenciesTable/DeletedAgenciesTable'
import { getDeletedAgenciesRequest, restoreAgencyRequest } from '../../API/requests/agenciesRequests'
import './DeletedAgenciesScreen.scss'
import ModalBase from '../../components/Common/ModalBase/ModalBase'

const DeletedAgenciesScreen = () => {
  const [deletedAgencies, setDeletedAgencies] = useState([])
  const [deletedAgenciesLoading, setDeletedAgenciesLoading] = useState(true)
  const [deletedAgenciesError, setDeletedAgenciesError] = useState(null)
  const [agenciesToResotre, setAgenciesToResotre] = useState([])
  const [modalError, setModalError] = useState(null)

  const fetchDeletedAgencies = async () => {
    try {
      setDeletedAgenciesLoading(true)
      setDeletedAgenciesError(null)
      const {
        data: { data },
      } = await getDeletedAgenciesRequest()
      setDeletedAgencies(data)
      setDeletedAgenciesLoading(false)
    } catch (error) {
      setDeletedAgenciesLoading(false)
      setDeletedAgenciesError('Error Getting Deleted Agencies')
    }
  }

  const restoreAgencyHandler = async agencyName => {
    setAgenciesToResotre([...agenciesToResotre, agencyName])
    const {
      data: { data, errorMessage },
    } = await restoreAgencyRequest(agencyName)
    setAgenciesToResotre(agenciesToResotre.filter(a => a !== agencyName))
    if (data === true) {
      fetchDeletedAgencies()
    } else {
      setModalError(errorMessage)
    }
  }

  useEffect(() => {
    fetchDeletedAgencies()
  }, [])

  return (
    <>
      {modalError && <ModalBase closeModal={() => setModalError(null)}>{modalError}</ModalBase>}
      <BaseScreen header={'Deleted Agencies'}>
        <div className='deleted-agencies-screen'>
          <div className='deleted-agencies-table-wrapper'>
            <DeletedAgenciesTable
              agencies={deletedAgencies}
              agenciesLoading={deletedAgenciesLoading}
              error={deletedAgenciesError}
              restoreAgency={restoreAgencyHandler}
              agenciesToResotre={agenciesToResotre}
            />
          </div>
        </div>
      </BaseScreen>
    </>
  )
}

export default DeletedAgenciesScreen
