import ProtectedRoute from '../../HOCs/ProtectedRoute/ProtectedRoute'
import ScreenWithNavbar from '../ScreenWithNavbar/ScreenWithNavbar'

const ProtectedScreenWithNavbar = ({ children }) => {
  return (
    <ProtectedRoute>
      <ScreenWithNavbar>{children}</ScreenWithNavbar>
    </ProtectedRoute>
  )
}

export default ProtectedScreenWithNavbar
