import './AgenciesTable.scss'

const AgenciesTable = ({ agencies = [], agenciesLoading = true, error = true, deleteAgency, agenciesToDelete }) => {
  if (error === true) {
    return <div className='agencies-table-placeholder'>Error. Something went wrong</div>
  }

  if (agenciesLoading) {
    return <div className='agencies-table-placeholder'>Loading...</div>
  }
  if (agencies.length === 0) {
    return <div className='agencies-table-placeholder'>No Agencies Found</div>
  }

  return (
    <>
      <div className='agencies-table-container'>
        <table>
          <thead>
            <tr>
              <th>Agency Name</th>
              <th>Agency DB Name</th>
              <th>Registration Number</th>
              <th>Agency Type</th>
              <th>Activation Status</th>
              <th>Last Download Date</th>
              <th>Downloaded Successfully</th>
              <th>Downloaded With Errors</th>
              <th>Not Downloaded</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {agencies.map(a => (
              <tr key={a.id}>
                <td>
                  <div>{a.name}</div>
                </td>
                <td>{a.dbName}</td>
                <td>{a.registrationNumber}</td>
                <td>{a.agencyType}</td>
                <td>{a.activationStatus} </td>
                <td>{a.lastDownloadDate} </td>
                <td>{a.downloadedSuccessfully} </td>
                <td>{a.downloadedWithError} </td>
                <td>{a.notDownloaded} </td>
                <td>
                  <button onClick={() => deleteAgency(a.id)} disabled={agenciesToDelete.includes(a.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default AgenciesTable
