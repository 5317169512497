import { useEffect, useState } from 'react'
import './ComapniesNotificationsTable.scss'
import ModalCompanyNewMessage from '../ModalCompanyNewMessage/ModalCompanyNewMessage'
import { getCompaniesNotesRequest, addCompanyNoteRequest } from '../../API/http'

const ComapniesNotificationsTable = () => {
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [companies, setCompanies] = useState([])
  const [companiesLoading, setCompaniesLoading] = useState(true)

  const updateCompanyMessageHandler =
    id =>
    ({ subject, body }) =>
      setCompanies(prev => prev.map(c => ({ ...c, ...(c.id === id && { subject, body }) })))

  const removeCompanyMessageHandler = async id => {
    const { data: result } = await addCompanyNoteRequest({ companyId: id, subject: '', body: '' })
    if (result) {
      updateCompanyMessageHandler(id)({ subject: null, body: null })
    }
  }

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        setCompaniesLoading(true)
        const {
          data: { data: companies },
        } = await getCompaniesNotesRequest()
        setCompanies(companies)
        setCompaniesLoading(false)
      } catch (error) {
        setCompaniesLoading(false)
      }
    }
    fetchCompanies()
  }, [])

  if (companiesLoading) {
    return <div className='companies-notifications-table-placeholder'>Loading...</div>
  }

  if (companies.length === 0) {
    return <div className='companies-notifications-placeholder'>No Companies Found</div>
  }

  return (
    <>
      <div className='companies-notifications-table-container'>
        <table>
          <thead>
            <tr>
              <th>Company</th>
              <th>HeadLine</th>
              <th>Text</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {companies.map(c => (
              <tr key={c.id}>
                <td>
                  <div>{c.name}</div>
                  <div>{c.englishName}</div>
                </td>
                <td>{c.subject && c.subject.length > 0 ? c.subject : '-'}</td>
                <td>{c.body && c.body.length > 0 ? c.body : '-'}</td>
                <td>
                  <button onClick={() => setSelectedCompany(c)}>{c.subject && c.body ? 'Edit' : 'Add'}</button>
                </td>
                <td>
                  {(c.subject || c.body) && <button onClick={() => removeCompanyMessageHandler(c.id)}>Remove</button>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedCompany && (
        <ModalCompanyNewMessage
          closeModal={() => setSelectedCompany(null)}
          company={selectedCompany}
          updateCompanyMessage={updateCompanyMessageHandler(selectedCompany.id)}
        />
      )}
    </>
  )
}

export default ComapniesNotificationsTable
