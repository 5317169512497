import { Fragment } from 'react'
import './DeletedAgenciesTable.scss'

const formatDate = date =>
  new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(date))

const DeletedAgenciesTable = ({
  agencies = [],
  agenciesLoading = true,
  error = true,
  restoreAgency,
  agenciesToResotre,
}) => {
  if (error) {
    return (
      <div className='deleted-agencies-table-placeholder'>
        Error. Something went wrong
        <br />
        {error}
      </div>
    )
  }

  if (agenciesLoading) {
    return <div className='deleted-agencies-table-placeholder'>Loading...</div>
  }
  if (agencies.length === 0) {
    return <div className='deleted-agencies-table-placeholder'>No Deleted Agencies Found</div>
  }

  return (
    <>
      <div className='deleted-agencies-table-container'>
        <table>
          <thead>
            <tr>
              <th>Agency Name</th>
              <th>Deleted Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {agencies.map(a => (
              <tr key={a.agencyName}>
                <td>
                  <div>{a.agencyName}</div>
                  {a.subAgencies.map(sa => (
                    <div
                      key={sa.agencyName}
                      style={{ paddingInlineStart: '12px', fontStyle: 'italic', fontSize: 'small' }}
                    >
                      {sa.agencyName}
                    </div>
                  ))}
                </td>
                <th>{formatDate(a.deletedDate)}</th>
                <td>
                  <button
                    onClick={() => restoreAgency(a.agencyName)}
                    disabled={agenciesToResotre.includes(a.agencyName)}
                  >
                    Restore
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default DeletedAgenciesTable
