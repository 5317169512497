import BaseScreen from '../../Containers/BaseScreen/BaseScreen'
import AgenciesFilters from '../../components/AgenciesFilters/AgenciesFilters'
import AgenciesTable from '../../components/AgenciesTable/AgenciesTable'
import { useDispatch, useSelector } from 'react-redux'
import './AgenciesScreen.scss'
import { removeAgencyRequest } from '../../API/requests/agenciesRequests'
import { getAgencies } from '../../store/actions/agenciesActions'
import { useState } from 'react'
import ModalBase from '../../components/Common/ModalBase/ModalBase'

const AgenciesScreen = () => {
  const { agencies, agenciesLoading, agenciesError } = useSelector(state => state.downloads)
  const [agenciesToDelete, setAgenciesToDelete] = useState([])
  const [modalError, setModalError] = useState(null)
  const dispatch = useDispatch()

  const deleteAgencyHandler = async agencyId => {
    setAgenciesToDelete([...agenciesToDelete, agencyId])
    const {
      data: { data, errorMessage },
    } = await removeAgencyRequest(agencyId)
    setAgenciesToDelete(agenciesToDelete.filter(a => a !== agencyId))
    if (data === true) {
      dispatch(getAgencies({ showLoading: false }))
    } else {
      setModalError(errorMessage)
    }
  }

  return (
    <>
      {modalError && <ModalBase closeModal={() => setModalError(null)}>{modalError}</ModalBase>}
      <BaseScreen header={'Agencies'}>
        <div className='agencies-screen'>
          <AgenciesFilters />
          <div className='agencies-table-wrapper'>
            <AgenciesTable
              agencies={agencies}
              agenciesLoading={agenciesLoading}
              error={agenciesError}
              deleteAgency={deleteAgencyHandler}
              agenciesToDelete={agenciesToDelete}
            />
          </div>
        </div>
      </BaseScreen>
    </>
  )
}

export default AgenciesScreen
