import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { baseRoute } from '../../API/routes'

const ProtectedRoute = ({ children }) => {
  const { userId } = useSelector(state => state.login)

  if (!Boolean(userId)) {
    return <Navigate to={baseRoute} replace />
  }

  return children
}

export default ProtectedRoute
