import './PolicyTypesTable.scss'

const PolicyTypesTable = ({
  policyTypes = [],
  policyTypesLoading = true,
  categories,
  categoriesLoading,
  updatePolicyType,
  error,
}) => {
  if (policyTypesLoading) {
    return <div className='policy-types-table-placeholder'>Loading...</div>
  }
  if (error) {
    return (
      <div className='policy-types-table-placeholder'>
        <p>Something Went Wrong...</p>
        <p>{error ?? 'Error'}</p>
      </div>
    )
  }
  if (policyTypes.length === 0) {
    return <div className='policy-types-table-placeholder'>No Policy Types Found</div>
  }

  const getAvailableCategories = categoryId => {
    const category = categories.find(c => c.id === categoryId)
    return categories.filter(c => c.fieldId === category.fieldId)
  }

  const updatePolicyTypeHandler =
    id =>
    ({ target: { value } }) =>
      updatePolicyType(id, +value)

  return (
    <>
      <div className='policy-types-table-container'>
        <table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Report Number</th>
              <th>Category</th>
            </tr>
          </thead>
          <tbody>
            {policyTypes.map(pt => (
              <tr key={pt.id}>
                <td>{pt.id}</td>
                <td>
                  <div>{pt.name}</div>
                </td>
                <td>{pt.reportNumber}</td>
                <td>
                  <select
                    name={`${pt.id}categories`}
                    id={`${pt.id}categoriesid`}
                    value={pt.categoryId}
                    onChange={updatePolicyTypeHandler(pt.id)}
                    disabled={categoriesLoading}
                  >
                    {getAvailableCategories(pt.categoryId).map(c => (
                      <option id={c.id} key={c.id} value={c.id}>
                        {c.englishName}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default PolicyTypesTable
