import { useEffect, useState } from 'react'
import { clientPage, clientPageType } from '../../types/clientPage'
import { clientMessageType, clientMessage } from '../../types/clientMessage'
import { inputCurrDateFull } from '../../utils/date'
import ModalBase from '../Common/ModalBase/ModalBase'
import './ModalNewMessage.scss'
import { useDispatch, useSelector } from 'react-redux'
import { addMessage } from '../../store/actions/downloadsActions'
import { MESSAGE_RESET } from '../../store/actionTypes'

const MINUTES_OFFSET = 10
const MIN_USERS_COUNT = 20
const currDate = inputCurrDateFull(
  new Date(inputCurrDateFull()).setMinutes(new Date(inputCurrDateFull()).getMinutes() + MINUTES_OFFSET)
)

const ModalNewMessage = ({ closeModal, type = clientMessageType.email }) => {
  const dispatch = useDispatch()
  const { users, selectedUsers, companiesList, messageLoading, messageSuccess } = useSelector(state => state.downloads)
  const [message, setMessage] = useState({
    subject: '',
    body: '',
    date: currDate,
    page: null,
    company: null,
  })
  const [showAllUsers, setShowAllUsers] = useState(false)

  const { formHeader, subjectLabel, bodyLabel, dateLabel } = clientMessage[type]

  const allUsersSelected = users.filter(u => selectedUsers.includes(u.id)).length === users.length

  const formIsValid = () => {
    if (
      message.subject.length === 0 ||
      message.body.length === 0 ||
      new Date(message.date) <= new Date(inputCurrDateFull())
    ) {
      return false
    }
    if (type !== clientMessageType.email) {
      if (!message.page || (+message.page === clientPageType.downloadsCompany && !message.company)) {
        return false
      }
    }
    return true
  }

  const inputsChangehandler = e => {
    const { name, value } = e.target
    setMessage(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const cancelHandler = e => {
    e.preventDefault()
    closeModal()
  }

  const submitlHandler = e => {
    e.preventDefault()
    const messageDto = {
      subject: message.subject,
      text: message.body,
      recepientIds: selectedUsers.join(','),
      sendTime: message.date,
      type: +(type === clientMessageType.email ? type : message.page),
      companies: message.page !== clientPageType.downloadsCompany ? message.company : null,
    }
    dispatch(addMessage(messageDto))
  }

  useEffect(() => {
    messageSuccess && closeModal()
    return () => {
      messageSuccess && dispatch({ type: MESSAGE_RESET })
    }
  }, [closeModal, messageSuccess, dispatch])

  return (
    <ModalBase closeModal={closeModal}>
      <div className='modal-new-msg-content'>
        <div className='modal-base-header'>
          <h3>{formHeader}</h3>
        </div>
        <form action='' onSubmit={submitlHandler}>
          <div className='modal-form-inputs-container'>
            <div className='modal-form-input-container'>
              <label htmlFor='name'>{subjectLabel}</label>
              <input
                type='text'
                id='subject'
                name='subject'
                value={message.subject}
                placeholder='Subject...'
                onChange={inputsChangehandler}
              />
            </div>
            <div className='modal-form-input-container'>
              <label htmlFor='story'>{bodyLabel}</label>
              <textarea
                id='body'
                name='body'
                value={message.body}
                placeholder='Message...'
                onChange={inputsChangehandler}
                rows={5}
              />
            </div>
            <div className='modal-form-input-container row'>
              <label htmlFor='date'>{dateLabel}</label>
              <input
                type='datetime-local'
                name='date'
                id='date'
                onChange={inputsChangehandler}
                value={message.date}
                min={inputCurrDateFull()}
              />
            </div>
            {type === clientMessageType.popUp && (
              <div className='modal-form-input-container row'>
                <div className='modal-form-input-container'>
                  <label htmlFor='page'>Choose a Page to Show a Message</label>
                  <select value={message.page ?? ''} onChange={inputsChangehandler} name='page' id='page'>
                    <option value='' disabled>
                      Page...
                    </option>
                    {Object.values(clientPage).map(p => (
                      <option key={p.value} value={p.value}>
                        {p.name}
                      </option>
                    ))}
                  </select>
                </div>
                {message.page === clientPageType.downloadsCompany.toString() && (
                  <div className='modal-form-input-container '>
                    <label htmlFor='company'>Choose Company</label>
                    <select value={message.company ?? ''} onChange={inputsChangehandler} name='company' id='company'>
                      <option value='' disabled>
                        Company...
                      </option>
                      {companiesList.map(c => (
                        <option key={c.id} value={c.id}>
                          {c.englishName}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            )}
            <div className='modal-form-input-container'>
              <label>
                Users Who Will Be Notified (
                {allUsersSelected ? 'all' : users.filter(u => selectedUsers.includes(u.id)).length})
              </label>
              {!allUsersSelected && (
                <div style={{ fontWeight: 'bold' }}>
                  {users
                    .filter(u => selectedUsers.includes(u.id))
                    .slice(0, !showAllUsers ? MIN_USERS_COUNT : users.filter(u => selectedUsers.includes(u.id)).length)
                    .map(u => u.fullName)
                    .join(', ')}
                  {users.filter(u => selectedUsers.includes(u.id)).length > MIN_USERS_COUNT && (
                    <span
                      onClick={() => setShowAllUsers(!showAllUsers)}
                      style={{
                        marginInlineStart: '6px',
                        fontWeight: 'normal',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >{`show ${showAllUsers ? 'less' : 'more...'}`}</span>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className='modal-form-actions-container'>
            <input type='submit' value='Send' onSubmit={submitlHandler} disabled={!formIsValid() || messageLoading} />
            <button onClick={cancelHandler}>Cancel</button>
          </div>
        </form>
      </div>
    </ModalBase>
  )
}

export default ModalNewMessage
