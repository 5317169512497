import { useDispatch, useSelector } from 'react-redux'
import { SELECT_ALL_USERS, SELECT_USER } from '../../store/actionTypes'
import './UsersNotificationsTable.scss'

const UsersNotificationsTable = () => {
  const dispatch = useDispatch()
  const { users, usersLoading, selectedUsers, selectedCompanies } = useSelector(state => state.downloads)
  const pages = 0

  const selectHandler = userId => {
    dispatch({ type: SELECT_USER, payload: userId })
  }

  const selectAllHandler = () => {
    dispatch({ type: SELECT_ALL_USERS })
  }

  const allUsersSelected = users.length === selectedUsers.length

  if (usersLoading) {
    return <div className='users-table-placeholder'>Loading...</div>
  }

  if (users.length === 0) {
    return <div className='users-table-placeholder'>No Users Found</div>
  }

  return (
    <>
      <div className='users-table-container'>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone</th>
              <th>Agency Number</th>
              <th>Agency Name</th>
              <th>Main Agent</th>
              <th>Last Notify</th>
              <th>
                <input
                  type='checkbox'
                  name=''
                  id=''
                  checked={allUsersSelected}
                  onChange={selectAllHandler}
                  disabled={false}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map(u => (
              <tr key={u.id}>
                <td>{u.fullName}</td>
                <td>{u.phoneNumber}</td>
                <td>
                  {u.agencyNumber ?? '-'}
                  <br />
                  <span style={{ fontSize: '12px', fontStyle: 'italic' }}>{u.agencyDbName ?? '-'}</span>
                </td>
                <td>{u.agencyName ?? '-'}</td>
                <td>{u.mainAgent ?? '-'}</td>
                <td>{u.latestNotification ? new Date(u.latestNotification).toLocaleString() : '-'}</td>
                <td>
                  <input
                    type='checkbox'
                    name=''
                    id=''
                    checked={selectedUsers.includes(u.id)}
                    onChange={() => selectHandler(u.id)}
                    disabled={false}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {pages > 0 && <div className='users-table-paging'>Pages</div>}
    </>
  )
}

export default UsersNotificationsTable
