export const clientMessageType = {
  email: 9,
  popUp: 'pop-up',
}

export const clientMessage = {
  [clientMessageType.email]: {
    formHeader: 'Create New Email Message',
    subjectLabel: 'Email subject',
    bodyLabel: 'Email Message',
    dateLabel: 'Date',
  },
  [clientMessageType.popUp]: {
    formHeader: 'Create New Pop-Up Message',
    subjectLabel: 'Pop-Up Headline',
    bodyLabel: 'Pop-Up Message',
    dateLabel: 'Date',
  },
}
