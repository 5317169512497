import BaseScreen from '../../Containers/BaseScreen/BaseScreen'
import ComapniesNotificationsTable from '../../components/ComapniesNotificationsTable/ComapniesNotificationsTable'
import './CompaniesNotifcationsScreen.scss'

const CompaniesNotifcationsScreen = () => {
  return (
    <BaseScreen header={'Companies Notifcations'}>
      <div className='companies-notifcations-screen'>
        <div className='companies-notifcations-table-wrapper'>
          <ComapniesNotificationsTable />
        </div>
      </div>
    </BaseScreen>
  )
}

export default CompaniesNotifcationsScreen
