import instance from '../http'
import { urlAccount } from '../urls'

export const addPrivacyTermsRequest = async ({ policyFile, termsFile }) => {
  const formData = new FormData()
  policyFile && formData.append('policyFile', policyFile, policyFile.name)
  termsFile && formData.append('termsFile', termsFile, termsFile.name)
  return await instance.post(`${urlAccount()}add-agreement`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const getPrivacyTermsRequest = async () => await instance.get(`${urlAccount()}get-agreements`)
