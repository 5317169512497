import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import { downloadsReducer } from './reducers/downloadsReducer.js'
import { loginReducer } from './reducers/loginReducer.js'
import { getItemFromLocalStorage } from '../services/localStorageService.js'

const reducer = combineReducers({
  downloads: downloadsReducer,
  login: loginReducer,
})

const userId = getItemFromLocalStorage('userId')

const initialState = {
  login: {
    userId,
  },
}
const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store
