import BaseScreen from '../../Containers/BaseScreen/BaseScreen'
import UsersFilters from '../../components/UsersFilters/UsersFilters'
import UsersTable from '../../components/UsersTable/UsersTable'
import { useSelector } from 'react-redux'
import './UsersScreen.scss'

const UsersScreen = () => {
  const { users, usersLoading} = useSelector(state => state.downloads)

  return (
    <>
      <BaseScreen header={'Users'}>
        <div className='users-screen'>          
          <UsersFilters />
          <div className='users-table-wrapper'>
            <UsersTable users={users} usersLoading={usersLoading} />
          </div>
        </div>
      </BaseScreen>
    </>
  )
}

export default UsersScreen
