import ProtectedScreenWithNavbar from '../Containers/ProtectedScreenWithNavbar/ProtectedScreenWithNavbar'
import AgenciesScreen from '../screens/AgenciesScreen/AgenciesScreen'
import CompaniesNotifcationsScreen from '../screens/CompaniesNotifcationsScreen/CompaniesNotifcationsScreen'
import DeletedAgenciesScreen from '../screens/DeletedAgenciesScreen/DeletedAgenciesScreen'
//import ChatbotScreen from '../screens/ChatbotScreen/ChatbotScreen'
import DownloadsManagementScreen from '../screens/DownloadsManagementScreen/DownloadsManagementScreen'
import DownloadsTrackingScreen from '../screens/DownloadsTrackingScreen/DownloadsTrackingScreen'
import LoginScreen from '../screens/LoginScreen/LoginScreen'
import NotificationsScreen from '../screens/NotificationsScreen/NotificationsScreen'
import PolicyTypesScreen from '../screens/PolicyTypesScreen/PolicyTypesScreen'
import PrivacyTermsScreen from '../screens/PrivacyTermsScreen/PrivacyTermsScreen'
import UsersScreen from '../screens/UsersScreen/UsersScreen'

export const baseRoute = '/'
export const downloadsTrackingRoute = `${baseRoute}downloads-tracking`
export const loginRoute = `${baseRoute}login`
export const downloadsManagementRoute = `${baseRoute}downloads-management`
export const agenciesRoute = `${baseRoute}agencies`
export const deletedAgenciesRoute = `${baseRoute}deleted-agencies`
export const notificationsRoute = `${baseRoute}notifications`
export const usersRoute = `${baseRoute}users`
export const companiesNotificationsRoute = `${baseRoute}companies-notifications`
export const privacyTermsRoute = `${baseRoute}privacy-terms`
export const policyTypesRoute = `${baseRoute}policy-types`

// export const chatbotRoute = `${baseRoute}chatbot`

export const routes = [
  {
    text: 'Login',
    route: loginRoute,
    element: <LoginScreen />,
    navbarItem: false,
  },
  {
    text: 'Downloads Tracking',
    route: downloadsTrackingRoute,
    element: (
      <ProtectedScreenWithNavbar>
        <DownloadsTrackingScreen />
      </ProtectedScreenWithNavbar>
    ),
    navbarItem: true,
  },
  {
    text: 'Downloads Management',
    route: downloadsManagementRoute,
    element: (
      <ProtectedScreenWithNavbar>
        <DownloadsManagementScreen />
      </ProtectedScreenWithNavbar>
    ),
    navbarItem: true,
  },
  {
    text: 'Notifications & Pop-ups',
    route: notificationsRoute,
    element: (
      <ProtectedScreenWithNavbar>
        <NotificationsScreen />
      </ProtectedScreenWithNavbar>
    ),
    navbarItem: true,
  },
  {
    text: 'Users',
    route: usersRoute,
    element: (
      <ProtectedScreenWithNavbar>
        <UsersScreen />
      </ProtectedScreenWithNavbar>
    ),
    navbarItem: true,
  },
  {
    text: 'Companies Notifications',
    route: companiesNotificationsRoute,
    element: (
      <ProtectedScreenWithNavbar>
        <CompaniesNotifcationsScreen />
      </ProtectedScreenWithNavbar>
    ),
    navbarItem: true,
  },
  {
    text: 'Agencies',
    route: agenciesRoute,
    element: (
      <ProtectedScreenWithNavbar>
        <AgenciesScreen />
      </ProtectedScreenWithNavbar>
    ),
    navbarItem: true,
  },
  {
    text: 'Deleted Agencies',
    route: deletedAgenciesRoute,
    element: (
      <ProtectedScreenWithNavbar>
        <DeletedAgenciesScreen />
      </ProtectedScreenWithNavbar>
    ),
    navbarItem: true,
  },
  {
    text: 'Privacy & Terms',
    route: privacyTermsRoute,
    element: (
      <ProtectedScreenWithNavbar>
        <PrivacyTermsScreen />
      </ProtectedScreenWithNavbar>
    ),
    navbarItem: true,
  },
  {
    text: 'Policy Types',
    route: policyTypesRoute,
    element: (
      <ProtectedScreenWithNavbar>
        <PolicyTypesScreen />
      </ProtectedScreenWithNavbar>
    ),
    navbarItem: true,
  },
  // {
  //   text: 'Chatbot',
  //   route: chatbotRoute,
  //   element: (
  //     <ScreenWithNavbar>
  //       <ChatbotScreen />
  //     </ScreenWithNavbar>
  //   ),
  //navbarItem: false
  // },
]
