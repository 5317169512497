export const getRandomDate = (start, end = new Date()) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))
}

export const getLastMonth = () => {
  const dayOfFirstDownload = 15
  const currentDate = new Date().getDate()
  const requiredMonth = new Date().getMonth() - (currentDate < dayOfFirstDownload ? 2 : 1)
  let requiredDate = new Date()
  requiredDate.setDate(1)
  requiredDate.setMonth(requiredMonth)

  return {
    date: createDateAsUTC(requiredDate),
    ...getFromTo(requiredDate.getFullYear(), requiredDate.getMonth()),
  }
}

export function createDateAsUTC(date) {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0))
}

export const getFromTo = (year, month) => {
  return { from: new Date(Date.UTC(year, month, 1, 0, 0, 0)), to: new Date(Date.UTC(year, month + 1, 0, 23, 59, 59)) }
}

export const getInputDateMonth = date => new Date(date).toISOString().substr(0, 7)
export const getInputDateFull = date => !isNaN(date) && new Date(date).toISOString().substr(0, 10)
export const getInputDateTime = date => new Date(date).toLocaleTimeString().substr(0, 5)

export const inputCurrDate = (date = getLastMonth().from) => getInputDateMonth(date)
export const inputCurrDateFull = (date = new Date()) => getInputDateFull(date) + 'T' + getInputDateTime(date)

export const inputPrevYearDate = () => {
  const min = new Date(inputCurrDate())
  min.setFullYear(min.getFullYear() - 1)
  min.setMonth(min.getMonth() + 1)
  return getInputDateMonth(min)
}
