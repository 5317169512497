import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCompaniesList } from '../../store/actions/downloadsActions'
import { getAgencies } from '../../store/actions/agenciesActions'
import useDebounce from '../../hooks/useDebounce'
import InputSelectMulti from '../Common/InputSelectMulti/InputSelectMulti'
import { CLEAR_SELECTED_COMPANIES, SELECT_ALL_COMPANIES, SELECT_COMPANIES } from '../../store/actionTypes'
import InputSearch from '../../components/InputSearch/InputSearch'
import './AgenciesFilters.scss'

const AgenciesFilters = () => {
  const dispatch = useDispatch()
  const { companiesList, selectedCompanies } = useSelector(state => state.downloads)
  const [agencyName, setAgencyName] = useState('')
  const [agencyDbName, setAgencyDbName] = useState('')
  const [registrationNumber, setRegistrationNumber] = useState('')
  const debouncedAgencyName = useDebounce(agencyName)
  const debouncedAgencyDbName = useDebounce(agencyDbName)
  const debouncedRegistrationNumber = useDebounce(registrationNumber)

  useEffect(() => {
    dispatch(getCompaniesList())
  }, [dispatch])

  useEffect(() => {
    dispatch(
      getAgencies({
        companiesIds: selectedCompanies,
        agencyName: debouncedAgencyName,
        agencyDbName: debouncedAgencyDbName,
        registrationNumber: debouncedRegistrationNumber,
      })
    )
  }, [dispatch, selectedCompanies, debouncedAgencyName, debouncedAgencyDbName, debouncedRegistrationNumber])

  const selectCompanyHandler = id => {
    dispatch({ type: SELECT_COMPANIES, payload: id })
  }

  const refreshHandler = () => {
    setAgencyName('')
    setAgencyDbName('')
    setRegistrationNumber('')
    dispatch({ type: CLEAR_SELECTED_COMPANIES })
  }

  return (
    <>
      <div className='agencies-filters'>
        <div className='agencies-search'>
          <InputSearch placeholder='Search agency name' value={agencyName} onChange={value => setAgencyName(value)} />
          <InputSearch
            placeholder='Search agency db name'
            value={agencyDbName}
            onChange={value => setAgencyDbName(value)}
          />
          <InputSearch
            placeholder='Search agency registration number'
            value={registrationNumber}
            onChange={value => setRegistrationNumber(value)}
          />
        </div>
        <div className='agencies-refresh'>
          <button
            onClick={refreshHandler}
            disabled={
              agencyName.length === 0 &&
              agencyDbName.length === 0 &&
              registrationNumber.length === 0 &&
              selectedCompanies.length === 0
            }
          >
            Refresh
          </button>
        </div>
      </div>
      <div className='agencies-filters'>
        <InputSelectMulti
          label='Filter by companies'
          items={companiesList.map(c => ({ ...c, value: c.englishName }))}
          selectedItems={selectedCompanies}
          selectItem={selectCompanyHandler}
          deselectItem={selectCompanyHandler}
          selectAll={() => dispatch({ type: SELECT_ALL_COMPANIES })}
          selectAllText={'Select All Companies'}
        />
      </div>
    </>
  )
}

export default AgenciesFilters
