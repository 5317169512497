import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../store/actions/loginActions'
import './MainHeader.scss'

const MainHeader = () => {
  const dispatch = useDispatch()
  const { userId } = useSelector(state => state.login)
  return (
    <header>
      <h1>Ai.Gent Admin Dashboard</h1>
      <button onClick={() => dispatch(logout())}>{`Logout User ${userId}`}</button>
    </header>
  )
}

export default MainHeader
