export const COMPANIES_LOADING = 'COMPANIES_LOADING'
export const COMPANIES_SUCCESS = 'COMPANIES_SUCCESS'
export const COMPANIES_FAIL = 'COMPANIES_FAIL'
export const COMPANIES_CLEAR = 'COMPANIES_CLEAR'
export const COMPANIES_LIST_LOADING = 'COMPANIES_LIST_LOADING'
export const COMPANIES_LIST_SUCCESS = 'COMPANIES_LIST_SUCCESS'
export const COMPANIES_LIST_FAIL = 'COMPANIES_LIST_FAIL'
export const SUBSCRIPTION_LIST_LOADING = 'SUBSCRIPTION_LIST_LOADING'
export const SUBSCRIPTION_LIST_SUCCESS = 'SUBSCRIPTION_LIST_SUCCESS'
export const SUBSCRIPTION_LIST_FAIL = 'SUBSCRIPTION_LIST_FAIL'
export const USERS_LOADING = 'USERS_LOADING'
export const USERS_SUCCESS = 'USERS_SUCCESS'
export const USERS_FAIL = 'USERS_FAIL'
export const USERS_ADD_NOTE = 'USERS_ADD_NOTE'
export const USER_DOWNLOADS_LOADING = 'USER_DOWNLOADS_LOADING'
export const USER_DOWNLOADS_SUCCESS = 'USER_DOWNLOADS_SUCCESS'
export const USER_DOWNLOADS_FAIL = 'USER_DOWNLOADS_FAIL'
export const USER_DOWNLOADS_CLEAR = 'USER_DOWNLOADS_CLEAR'
export const DOWNLOADS_STATS_LOADING = 'DOWNLOADS_STATS_LOADING'
export const DOWNLOADS_STATS_SUCCESS = 'DOWNLOADS_STATS_SUCCESS'
export const DOWNLOADS_STATS_FAIL = 'DOWNLOADS_STATS_FAIL'
export const DOWNLOADS_STATS_CLEAR = 'DOWNLOADS_STATS_CLEAR'
export const USER_REPORTS_LOADING = 'USER_REPORTS_LOADING'
export const USER_REPORTS_SUCCESS = 'USER_REPORTS_SUCCESS'
export const USER_REPORTS_FAIL = 'USER_REPORTS_FAIL'
export const USER_REPORTS_CLEAR = 'USER_REPORTS_CLEAR'
export const PREF_UPDATE_LOADING = 'PREF_UPDATE_LOADING'
export const PREF_UPDATE_SUCCESS = 'PREF_UPDATE_SUCCESS'
export const PREF_UPDATE_FAIL = 'PREF_UPDATE_FAIL'
export const SELECT_USER = 'SELECT_USER'
export const SELECT_ALL_USERS = 'SELECT_ALL_USERS'
export const SELECT_COMPANIES = 'SELECT_COMPANIES'
export const SELECT_ALL_COMPANIES = 'SELECT_ALL_COMPANIES'
export const SELECT_SUBSCRIPTIONS = 'SELECT_SUBSCRIPTIONS'
export const SELECT_ALL_SUBSCRIPTIONS = 'SELECT_ALL_SUBSCRIPTIONS'
export const SET_MONTH = 'SET_MONTH'
export const MESSAGE_LOADING = 'MESSAGE_LOADING'
export const MESSAGE_SUCCESS = 'MESSAGE_SUCCESS'
export const MESSAGE_FAIL = 'MESSAGE_FAIL'
export const MESSAGE_RESET = 'MESSAGE_RESET'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const AGENCIES_LOADING = 'AGENCIES_LOADING'
export const AGENCIES_SUCCESS = 'AGENCIES_SUCCESS'
export const AGENCIES_FAIL = 'AGENCIES_FAIL'
export const AGENCIES_CLEAR = 'AGENCIES_CLEAR'
export const CLEAR_SELECTED_COMPANIES = 'CLEAR_SELECTED_COMPANIES'