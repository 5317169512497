import { LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT } from '../actionTypes'

const initialState = {}
export const loginReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        userId: null,
        userLoading: true,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        userLoading: false,
        userId: payload,
      }
    case LOGIN_FAIL:
      return {
        ...state,
        userLoading: false,
        userId: null,
        userError: payload,
      }
    case LOGOUT:
      return {
        ...state,
        userLoading: false,
        userId: null,
        userError: null,
      }
    default:
      return state
  }
}
