import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { baseRoute } from '../../API/routes'
import { login } from '../../store/actions/loginActions'
import './LoginScreen.scss'

const LoginScreen = () => {
  const { userId } = useSelector(state => state.login)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [data, setData] = useState({
    email: '',
    password: '',
    // email: 'test@test.com',
    // password: 'Qwerty1',
  })

  const changeHanlder = e => {
    const { name, value } = e.target
    setData(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const submitHandler = e => {
    e.preventDefault()
    const { email, password } = data
    dispatch(login(email, password))
  }

  useEffect(() => {
    if (userId) {
      navigate(baseRoute)
    }
  }, [userId, navigate])

  return (
    <div className='login-screen-container'>
      <form action='' onSubmit={submitHandler}>
        <input type='text' value={data.email} onChange={changeHanlder} name={'email'} />
        <input type='password' value={data.password} onChange={changeHanlder} name={'password'} />
        <input type='submit' value='Login' />
      </form>
    </div>
  )
}

export default LoginScreen
