import { useState } from 'react'
import ModalBase from '../Common/ModalBase/ModalBase'
import './ModalCompanyNewMessage.scss'
import { addCompanyNoteRequest } from '../../API/http'

const ModalCompanyNewMessage = ({ closeModal, company, updateCompanyMessage }) => {
  const [submitLoading, setSubmitLoading] = useState(false)
  const [message, setMessage] = useState({
    subject: company.subject ?? '',
    body: company.body ?? '',
  })
  const [formIsDirty, setFormIsDirty] = useState(false)

  const formIsValid = (message.subject.length > 0) & (message.body.length > 0)

  const submitDisabled = !formIsValid || submitLoading

  const inputsChangehandler = e => {
    const { name, value } = e.target
    setFormIsDirty(true)
    setMessage(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const cancelHandler = e => {
    e.preventDefault()
    closeModal()
  }

  const submitlHandler = async e => {
    e.preventDefault()
    if (formIsDirty) {
      try {
        setSubmitLoading(true)
        const { data: result } = await addCompanyNoteRequest({
          companyId: company.id,
          subject: message.subject,
          body: message.body,
        })
        if (result) {
          updateCompanyMessage(message)
        }
        setSubmitLoading(false)
      } catch (error) {
        setSubmitLoading(false)
      }
    }
    closeModal()
  }

  return (
    <ModalBase closeModal={closeModal}>
      <div className='modal-new-msg-content'>
        <div className='modal-base-header'>
          <h3>{`${company.subject && company.body ? 'Edit' : 'Add'} ${company.name} (${
            company.englishName
          }) Message`}</h3>
        </div>
        <form action='' onSubmit={submitlHandler}>
          <div className='modal-form-inputs-container'>
            <div className='modal-form-input-container'>
              <label htmlFor='subject'>{'Subject'}</label>
              <input
                type='text'
                id='subject'
                name='subject'
                value={message.subject}
                placeholder='Subject...'
                onChange={inputsChangehandler}
              />
            </div>
            <div className='modal-form-input-container'>
              <label htmlFor='body'>{'Message'}</label>
              <textarea
                id='body'
                name='body'
                value={message.body}
                placeholder='Message...'
                onChange={inputsChangehandler}
                rows={5}
              />
            </div>
          </div>
          <div className='modal-form-actions-container'>
            <input type='submit' value='Send' onSubmit={submitlHandler} disabled={submitDisabled} />
            <button onClick={cancelHandler}>Cancel</button>
          </div>
        </form>
      </div>
    </ModalBase>
  )
}

export default ModalCompanyNewMessage
