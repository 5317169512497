import {  useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InputSelectMulti from '../../components/Common/InputSelectMulti/InputSelectMulti'
import InputSearch from '../../components/InputSearch/InputSearch'
import useDebounce from '../../hooks/useDebounce'
import { getCompaniesList, getSubscriptionsList, getUsers } from '../../store/actions/downloadsActions'
import { SELECT_ALL_COMPANIES, SELECT_ALL_SUBSCRIPTIONS, SELECT_COMPANIES, SELECT_SUBSCRIPTIONS } from '../../store/actionTypes'
import './UsersFilters.scss'

const UsersFilters = () => {
  const dispatch = useDispatch()
  const { companiesList, selectedCompanies, subscriptionsList, selectedSubscriptions } = useSelector(state => state.downloads)

  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [agencyName, setAgencyName] = useState('')
  const debouncedName = useDebounce(name)
  const debouncedPhoneNumber = useDebounce(phoneNumber)
  const debouncedAgencyName = useDebounce(agencyName)

  const refreshHandler = () => {
    setName('')
    setPhoneNumber('')
    setAgencyName('')
  }

  const selectCompanyHandler = id => {
    dispatch({ type: SELECT_COMPANIES, payload: id })
  }

  const selectSubscription = id => {
    dispatch({ type: SELECT_SUBSCRIPTIONS, payload: id })
  }

  useEffect(() => {
    dispatch(
      getUsers(
        { companyIds: selectedCompanies,
          subscriptionIds: selectedSubscriptions,
          userName: debouncedName,
          userPhone: debouncedPhoneNumber,
          agencyName: debouncedAgencyName }
      )
    )
  }, [dispatch, selectedCompanies, selectedSubscriptions, debouncedName, debouncedPhoneNumber, debouncedAgencyName])

  useEffect(() => {
    dispatch(getCompaniesList())
    dispatch(getSubscriptionsList())
  }, [dispatch])

  return (
    <>
      <div className='users-filters'>
        <div className='users-search'>
          <InputSearch placeholder='Search User Name' value={name} onChange={value => setName(value)} />
          <InputSearch
            placeholder='Search Phone Number (+972)'
            value={phoneNumber}
            onChange={value => setPhoneNumber(value)}
          />
          <InputSearch
            placeholder='Search Agency Name'
            value={agencyName}
            onChange={value => setAgencyName(value)}
          />
        </div>
        <div className='users-refresh'>
          <button onClick={refreshHandler} disabled={name.length === 0 && phoneNumber.length === 0}>
            Refresh
          </button>
        </div>
      </div>
      <div className='users-filters'>
        <InputSelectMulti
          label='Filter by companies'
          items={companiesList.map(c => ({ ...c, value: c.englishName }))}
          selectedItems={selectedCompanies}
          selectItem={selectCompanyHandler}
          deselectItem={selectCompanyHandler}
          selectAll={() => dispatch({ type: SELECT_ALL_COMPANIES })}
          selectAllText={'Select All Companies'}
        />
      </div>
      <div className='users-filters'>
        <InputSelectMulti
          label='Filter by subscription'
          items={subscriptionsList.map(s => ({ ...s, value: s.name }))}
          selectedItems={selectedSubscriptions}
          selectItem={selectSubscription}
          deselectItem={selectSubscription}
          selectAll={() => dispatch({ type: SELECT_ALL_SUBSCRIPTIONS })}
          selectAllText={'Select All Subscriptions'}
        />
      </div>
    </>
  )
}

export default UsersFilters
