import { useState, useEffect, useCallback } from 'react'
import { lowercaseObjectKeys } from '../utils/objectUtils'

export default function useApiRequest(
  request,
  { requestParams, loadingOnInit = true, dataInit, logError, runOnInit = true } = {}
) {
  const [loading, setLoading] = useState(loadingOnInit)
  const [runOnStart, setRunOnStart] = useState(runOnInit)
  const [data, setData] = useState(dataInit)
  const [error, setError] = useState(null)

  const fetch = useCallback(
    async ({ silentLoading = false, params } = {}) => {
      try {
        setRunOnStart(false)
        setError(null)
        if (!silentLoading) {
          setLoading(true)
          setData(dataInit)
        }
        const { data } = await request(params ?? requestParams)
        setData(data.Data ? lowercaseObjectKeys(data.Data) : data.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        let err = 'Error'
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          err = error.response.data
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          //console.log(error.request);
          err = error.request
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message)
          err = error.message
        }
        logError && console.log('fetch error: ', err)
        setError(err)
      }
    },
    [request, logError, dataInit, requestParams]
  )

  const refetch = ({ silentLoading = false, params } = {}) => fetch({ silentLoading, params })

  useEffect(() => {
    runOnStart && fetch()
  }, [fetch, runOnStart])

  return { loading, data, error, fetch: refetch }
}
