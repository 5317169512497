let adminUrl = '/api'
let masterUrl = '/api'

if (process.env.REACT_APP_API_ENV === 'development') {
  // -- Local URL ----------------------------------
  adminUrl = `https://localhost:5007${adminUrl}`
  masterUrl = `https://localhost:5005${masterUrl}`
  // ------------------------------------------------
} else if (process.env.REACT_APP_API_ENV === 'development-stage') {
  // -- stage URL from dev ------------------------------
  adminUrl = `https://stage-admin.ai-gentins.com/api${adminUrl}`
  masterUrl = `https://stage-admin.ai-gentins.com/${masterUrl}`
} else if (process.env.REACT_APP_API_ENV === 'development-prod') {
  // -- production URL from dev ------------------------------
  adminUrl = `https://admin.ai-gentins.com/api${adminUrl}`
  masterUrl = `https://admin.ai-gentins.com/${masterUrl}`
} else {
  // -- production URL ------------------------------
  adminUrl = `/api${adminUrl}`
  masterUrl = `${masterUrl}`
}

// Admin URLS
export const BASE_URL = adminUrl
const accountUrl = 'account/'
const reportsUrl = 'reports/'
const companyUrl = 'company/'
const notifyUrl = 'notification/'
const agenciesUrl = 'agencies/'
const policyTypesUrl = 'policytypes/'

export const urlAccount = () => `${accountUrl}`
export const urlReports = () => `${reportsUrl}`
export const urlCompany = () => `${companyUrl}`
export const urlNotify = () => `${notifyUrl}`
export const urlAgencies = () => `${agenciesUrl}`
export const urlPolicyTypes = () => `${policyTypesUrl}`

//Master URLS
export const MASTER_URL = masterUrl
const masterCategoryUrl = 'category/'

export const urlMasterCategory = () => `${masterCategoryUrl}`
