import {
  COMPANIES_SUCCESS,
  COMPANIES_LOADING,
  COMPANIES_FAIL,
  PREF_UPDATE_FAIL,
  PREF_UPDATE_LOADING,
  PREF_UPDATE_SUCCESS,
  USERS_FAIL,
  USERS_LOADING,
  USERS_SUCCESS,
  COMPANIES_LIST_LOADING,
  COMPANIES_LIST_SUCCESS,
  COMPANIES_LIST_FAIL,
  USER_REPORTS_LOADING,
  USER_REPORTS_SUCCESS,
  USER_REPORTS_FAIL,
  USER_DOWNLOADS_LOADING,
  USER_DOWNLOADS_SUCCESS,
  USER_DOWNLOADS_FAIL,
  DOWNLOADS_STATS_LOADING,
  DOWNLOADS_STATS_SUCCESS,
  DOWNLOADS_STATS_FAIL,
  MESSAGE_LOADING,
  MESSAGE_SUCCESS,
  MESSAGE_FAIL,
  SUBSCRIPTION_LIST_LOADING,
  SUBSCRIPTION_LIST_SUCCESS,
  SUBSCRIPTION_LIST_FAIL,
} from '../actionTypes'
import {
  addMessageRequest,
  getCompaniesListRequest,
  getCompaniesRequest,
  getSubscriptionsListRequest,
  getUserDownloadsRequest,
  getUserDownloadsStatsRequest,
  getUserReportsRequest,
  getUsersRequest,
} from '../../API/http'
import { delay } from '../../utils/delay'
import { getRandomInt } from '../../utils/number'

export const getCompanies = (agency, phoneNumber, month) => async dispatch => {
  dispatch({ type: COMPANIES_LOADING })
  try {
    const data = await getCompaniesRequest(agency, phoneNumber, month)
    dispatch({
      type: COMPANIES_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: COMPANIES_FAIL,
      payload: error.message,
    })
  }
}

export const getUserReports = agency => async dispatch => {
  dispatch({ type: USER_REPORTS_LOADING })
  try {
    let data = await getUserReportsRequest(agency)
    data = Object.entries(data).map(([k, v]) => ({ number: k, name: v }))
    dispatch({
      type: USER_REPORTS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_REPORTS_FAIL,
      payload: error,
    })
  }
}

export const getUserDownloads = (agency, month) => async dispatch => {
  dispatch({ type: USER_DOWNLOADS_LOADING })
  try {
    const data = await getUserDownloadsRequest(agency, month)
    dispatch({
      type: USER_DOWNLOADS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_DOWNLOADS_FAIL,
      payload: error,
    })
  }
}

export const getUserDownloadsStats = (agency, month) => async dispatch => {
  dispatch({ type: DOWNLOADS_STATS_LOADING })
  try {
    const data = await getUserDownloadsStatsRequest(agency, month)
    dispatch({
      type: DOWNLOADS_STATS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DOWNLOADS_STATS_FAIL,
      payload: error,
    })
  }
}

export const updateReportPref = (reportId, value) => async dispatch => {
  dispatch({ type: PREF_UPDATE_LOADING })
  try {
    await delay(getRandomInt(500, 1500))
    const success = true
    dispatch({
      type: PREF_UPDATE_SUCCESS,
      payload: { reportId, success, value },
    })
  } catch (error) {
    dispatch({
      type: PREF_UPDATE_FAIL,
      payload: error.message,
    })
  }
}

export const getUsers =
  ({ companyIds, subscriptionIds, agency, userName, userPhone, agencyName }) =>
  async dispatch => {
    dispatch({ type: USERS_LOADING })
    try {
      const data = await getUsersRequest({ companyIds, subscriptionIds, agency, userName, userPhone, agencyName })
      dispatch({
        type: USERS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: USERS_FAIL,
        payload: error,
      })
    }
  }

export const getCompaniesList = () => async dispatch => {
  dispatch({ type: COMPANIES_LIST_LOADING })
  try {
    const data = await getCompaniesListRequest()
    dispatch({
      type: COMPANIES_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: COMPANIES_LIST_FAIL,
      payload: error,
    })
  }
}

export const getSubscriptionsList = () => async dispatch => {
  dispatch({ type: SUBSCRIPTION_LIST_LOADING })
  try {
    const data = await getSubscriptionsListRequest()
    dispatch({
      type: SUBSCRIPTION_LIST_SUCCESS,
      payload: data.data,
    })
  } catch (error) {
    dispatch({
      type: SUBSCRIPTION_LIST_FAIL,
      payload: error,
    })
  }
}

export const addMessage = message => async dispatch => {
  dispatch({ type: MESSAGE_LOADING })
  try {
    const data = await addMessageRequest(message)
    dispatch({
      type: MESSAGE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: MESSAGE_FAIL,
      payload: error.message,
    })
  }
}
