import { inputCurrDate } from '../../utils/date'
import {
  COMPANIES_CLEAR,
  COMPANIES_FAIL,
  COMPANIES_LIST_FAIL,
  COMPANIES_LIST_LOADING,
  COMPANIES_LIST_SUCCESS,
  COMPANIES_LOADING,
  COMPANIES_SUCCESS,
  DOWNLOADS_STATS_CLEAR,
  DOWNLOADS_STATS_FAIL,
  DOWNLOADS_STATS_LOADING,
  DOWNLOADS_STATS_SUCCESS,
  MESSAGE_FAIL,
  MESSAGE_LOADING,
  MESSAGE_RESET,
  MESSAGE_SUCCESS,
  PREF_UPDATE_FAIL,
  PREF_UPDATE_LOADING,
  PREF_UPDATE_SUCCESS,
  SELECT_ALL_COMPANIES,
  SELECT_ALL_SUBSCRIPTIONS,
  SELECT_ALL_USERS,
  SELECT_COMPANIES,
  SELECT_SUBSCRIPTIONS,
  SELECT_USER,
  SET_MONTH,
  SUBSCRIPTION_LIST_FAIL,
  SUBSCRIPTION_LIST_LOADING,
  SUBSCRIPTION_LIST_SUCCESS,
  USERS_ADD_NOTE,
  USERS_FAIL,
  USERS_LOADING,
  USERS_SUCCESS,
  USER_DOWNLOADS_CLEAR,
  USER_DOWNLOADS_FAIL,
  USER_DOWNLOADS_LOADING,
  USER_DOWNLOADS_SUCCESS,
  USER_REPORTS_CLEAR,
  USER_REPORTS_FAIL,
  USER_REPORTS_LOADING,
  USER_REPORTS_SUCCESS,
  AGENCIES_LOADING,
  AGENCIES_SUCCESS,
  AGENCIES_FAIL,
  AGENCIES_CLEAR,
  CLEAR_SELECTED_COMPANIES,
} from '../actionTypes'
const initStats = { downloadAttempted: 0, downloadNotAttempted: 0 }

const initialState = {
  companies: [],
  companiesLoading: false,
  userDownloads: null,
  userDownloadsLoading: false,
  userStats: initStats,
  userStatsLoading: false,
  userReports: null,
  userReportsLoading: false,
  updateReportSucces: false,
  updateReportLoading: false,
  users: [],
  usersLoading: false,
  companiesList: [],
  companiesListLoading: false,
  selectedUsers: [],
  selectedCompanies: [],
  subscriptionsList: [],
  subscriptionsListLoading: false,
  selectedSubscriptions: [],
  month: inputCurrDate(),
  messageLoading: false,
  messageSuccess: false,
  agenciesLoading: false,
  agencies: [],
}
export const downloadsReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case COMPANIES_LOADING:
      return {
        ...state,
        companiesLoading: true,
        companies: [],
      }
    case COMPANIES_SUCCESS:
      return {
        ...state,
        companiesLoading: false,
        companies: payload,
      }
    case COMPANIES_FAIL:
      return {
        ...state,
        companiesLoading: false,
        companiesError: payload,
      }
    case COMPANIES_CLEAR:
      return {
        ...state,
        companiesLoading: false,
        companiesError: null,
        companies: [],
      }
    case USER_DOWNLOADS_LOADING:
      return {
        ...state,
        userDownloadsLoading: true,
        userDownloads: null,
        userDownloadsError: null,
      }
    case USER_DOWNLOADS_SUCCESS:
      return {
        ...state,
        userDownloadsLoading: false,
        userDownloads: payload,
        userDownloadsError: null,
      }
    case USER_DOWNLOADS_FAIL:
      return {
        ...state,
        userDownloadsLoading: false,
        userDownloadsError: payload,
      }
    case USER_DOWNLOADS_CLEAR:
      return {
        ...state,
        userDownloadsLoading: false,
        userDownloads: null,
        userDownloadsError: null,
      }
    case DOWNLOADS_STATS_LOADING:
      return {
        ...state,
        userStatsLoading: true,
        userStats: initStats,
        userStatsError: null,
      }
    case DOWNLOADS_STATS_SUCCESS:
      return {
        ...state,
        userStatsLoading: false,
        userStats: payload,
        userStatsError: null,
      }
    case DOWNLOADS_STATS_FAIL:
      return {
        ...state,
        userStatsLoading: false,
        userStatsError: payload,
      }
    case DOWNLOADS_STATS_CLEAR:
      return {
        ...state,
        userStatsLoading: false,
        userStats: initStats,
        userStatsError: null,
      }
    case USER_REPORTS_LOADING:
      return {
        ...state,
        userReportsLoading: true,
        userReports: null,
        userReportsError: null,
      }
    case USER_REPORTS_SUCCESS:
      return {
        ...state,
        userReportsLoading: false,
        userReports: payload,
        userReportsError: null,
      }
    case USER_REPORTS_FAIL:
      return {
        ...state,
        userReportsLoading: false,
        userReportsError: payload,
      }
    case USER_REPORTS_CLEAR:
      return {
        ...state,
        userReportsLoading: false,
        userReports: null,
        userReportsError: null,
      }
    case PREF_UPDATE_LOADING:
      return {
        ...state,
        updateReportLoading: true,
        updateReportSucces: false,
        updateReportError: null,
        updateReportId: payload,
      }
    case PREF_UPDATE_SUCCESS:
      const { reportId: updateReportId, success, value } = payload
      return {
        ...state,
        updateReportLoading: false,
        updateReportSucces: success,
        updateReportError: null,
        updateReportId: updateReportId,
        userDownloads: success
          ? {
              ...state.userDownloads,
              reports: state.userDownloads.reports.map(r =>
                r.id === updateReportId ? { ...r, downloadAllowed: value } : r
              ),
            }
          : state.userDownloads,
      }
    case PREF_UPDATE_FAIL:
      return {
        ...state,
        updateReportLoading: false,
        updateReportSucces: false,
        updateReportError: payload,
        updateReportId: null,
      }
    case USERS_LOADING:
      return {
        ...state,
        usersLoading: true,
        users: [],
        usersError: null,
      }
    case USERS_SUCCESS:
      return {
        ...state,
        usersLoading: false,
        users: payload,
        usersError: null,
        //selectedUsers: payload.map(u => u.id),
      }
    case USERS_FAIL:
      return {
        ...state,
        usersLoading: false,
        usersError: payload,
      }
    case USERS_ADD_NOTE:
      const { id, note } = payload
      return {
        ...state,
        users: state.users.map(u => ({ ...u, popupMessage: u.id === id ? note : u.popupMessage })),
      }
    case COMPANIES_LIST_LOADING:
      return {
        ...state,
        companiesListLoading: true,
        companiesList: [],
        companiesListError: null,
      }
    case COMPANIES_LIST_SUCCESS:
      return {
        ...state,
        companiesListLoading: false,
        companiesList: payload,
        companiesListError: null,
      }
    case COMPANIES_LIST_FAIL:
      return {
        ...state,
        companiesListLoading: false,
        companiesListError: payload,
      }
    case SELECT_USER:
      return {
        ...state,
        selectedUsers: state.selectedUsers.includes(payload)
          ? state.selectedUsers.filter(u => u !== payload)
          : [...state.selectedUsers, payload],
      }
    case SELECT_ALL_USERS:
      return {
        ...state,
        selectedUsers: state.selectedUsers.length === state.users.length ? [] : state.users.map(u => u.id),
      }
    case SELECT_COMPANIES:
      return {
        ...state,
        selectedCompanies: state.selectedCompanies.includes(payload)
          ? state.selectedCompanies.filter(c => c !== payload)
          : [...state.selectedCompanies, payload],
        selectedUsers: [],
      }
    case SELECT_ALL_COMPANIES:
      const { selectedCompanies, companiesList } = state
      return {
        ...state,
        selectedCompanies: selectedCompanies.length === companiesList.length ? [] : companiesList.map(c => c.id),
        selectedUsers: [],
      }
    case SUBSCRIPTION_LIST_LOADING:
      return {
        ...state,
        subscriptionsListLoading: true,
        subscriptionsList: [],
        subscriptionsListError: null,
      }
    case SUBSCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        subscriptionsListLoading: false,
        subscriptionsList: payload,
        subscriptionsListError: null,
      }
    case SUBSCRIPTION_LIST_FAIL:
      return {
        ...state,
        subscriptionsListLoading: false,
        subscriptionsListError: payload,
      }
    case SELECT_SUBSCRIPTIONS:
      return {
        ...state,
        selectedSubscriptions: state.selectedSubscriptions.includes(payload)
          ? state.selectedSubscriptions.filter(c => c !== payload)
          : [...state.selectedSubscriptions, payload],
        selectedUsers: [],
      }
    case SELECT_ALL_SUBSCRIPTIONS:
      const { selectedSubscriptions, subscriptionsList } = state
      return {
        ...state,
        selectedSubscriptions:
          selectedSubscriptions.length === subscriptionsList.length ? [] : subscriptionsList.map(c => c.id),
        selectedUsers: [],
      }
    case SET_MONTH:
      return {
        ...state,
        month: payload,
      }
    case MESSAGE_LOADING:
      return {
        ...state,
        messageLoading: true,
        messageSuccess: false,
        messageError: null,
      }
    case MESSAGE_SUCCESS:
      return {
        ...state,
        messageLoading: false,
        messageSuccess: payload,
        messageError: null,
      }
    case MESSAGE_FAIL:
      return {
        ...state,
        messageLoading: false,
        messageSuccess: false,
        messageError: payload,
      }
    case MESSAGE_RESET:
      return {
        ...state,
        messageLoading: false,
        messageSuccess: false,
        messageError: null,
      }
    case AGENCIES_LOADING:
      return {
        ...state,
        agenciesLoading: payload,
        agenciesError: null,
      }
    case AGENCIES_SUCCESS:
      return {
        ...state,
        agenciesLoading: false,
        agencies: payload,
        agenciesError: false,
      }
    case AGENCIES_FAIL:
      return {
        ...state,
        agenciesLoading: false,
        agenciesError: true,
      }
    case AGENCIES_CLEAR:
      return {
        ...state,
        agenciesLoading: false,
        agencies: [],
        agenciesError: false,
      }
    case CLEAR_SELECTED_COMPANIES:
      return {
        ...state,
        agenciesLoading: false,
        selectedCompanies: [],
        agenciesError: false,
      }
    default:
      return state
  }
}
