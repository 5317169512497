import { useEffect, useState } from 'react'
import { getReportStatusDetails } from '../../API/http'
import { createDateAsUTC } from '../../utils/date'
import ModalBase from '../Common/ModalBase/ModalBase'
import './ModalReportStatusDetails.scss'

const ModalReportStatusDetails = ({ closeModal, report }) => {
  const [agencies, setAgencies] = useState([])
  const [agenciesLoading, setAgenciesLoading] = useState(true)
  const [agenciesError, setAgenciesError] = useState(null)

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setAgencies([])
        setAgenciesLoading(true)
        const month = createDateAsUTC(new Date(report.month))
        const { data: { agencies: data } } = await getReportStatusDetails(month, report.reportNumber, report.statuses)
        setAgencies(data)
      } catch (error) {
        setAgenciesError('Server error')
      }
      finally {
        setAgenciesLoading(false)
      }
    }
    fetchDetails()
  }, [report])

  return (
    <ModalBase closeModal={closeModal}>
      <div className='report-status-details-modal-content'>
        <div className='modal-base-header'>
          <h3>Details for <span style={{ fontStyle: 'italic' }}>{report.reportName}</span></h3>
          <h4>Report Number: <span style={{ fontStyle: 'italic' }}>{report.reportNumber}</span></h4>
          <h4>Report Month: <span style={{ fontStyle: 'italic' }}>{report.month}</span></h4>
        </div>
        <div>
          <h4>Agencies ({agencies.length}), which got <span>{report.statuses.join(', ')}</span></h4>
          <ul style={{ height: '346px', listStyle: 'none', overflow: 'auto' }}>{agencies.map((a, i) => (
            <li key={i} style={{ marginBottom: '6px' }}>{a}</li>
          ))}</ul>
        </div>
      </div>
    </ModalBase>
  )
}

export default ModalReportStatusDetails
