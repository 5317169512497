import { useEffect, useState } from 'react'
import BaseScreen from '../../Containers/BaseScreen/BaseScreen'
import { addPrivacyTermsRequest, getPrivacyTermsRequest } from '../../API/requests/privacyTermsRequests'
import parse from 'html-react-parser'
import './PrivacyTermsScreen.scss'

const PrivacyTermsScreen = () => {
  const [policyFile, setPolicyFile] = useState(null)
  const [termsFile, setTermsFile] = useState(null)
  const [uploadLoading, setUploadLoading] = useState(false)
  const [uploadError, setUploadError] = useState(null)
  const [fetchTermsLoading, setFetchTermsLoading] = useState(true)
  const [fetchTermsError, setFetchTermsError] = useState(null)
  const [terms, setTerms] = useState(null)

  const submitHandler = async e => {
    e.preventDefault()
    try {
      setUploadError(null)
      setUploadLoading(true)
      const { data } = await addPrivacyTermsRequest({ policyFile, termsFile })
      if (!data.data) {
        setUploadError(data.errorMessage)
      }
      fetchTerms()
      setUploadLoading(false)
    } catch (error) {
      setUploadError('Upload Error')
      setUploadLoading(false)
    }
  }

  const fetchTerms = async () => {
    try {
      setFetchTermsError(null)
      setFetchTermsLoading(true)
      const {
        data: { data },
      } = await getPrivacyTermsRequest()
      if (!data) {
        throw new Error('Error fetching')
      }
      setTerms(data)
      setFetchTermsLoading(false)
    } catch (error) {
      setFetchTermsError('Error fetching')
      setFetchTermsLoading(false)
    }
  }

  useEffect(() => {
    fetchTerms()
    console.log(document.head.style.app)
  }, [])

  return (
    <BaseScreen header={'Privacy Policy & Terms of Use'}>
      <div className='privacy-terms-screen'>
        <section>
          <form onSubmit={submitHandler}>
            <div>
              <div>
                <h5>Terms of Use (docx)</h5>
                <input type='file' name='terms' id='terms' onChange={e => setTermsFile(e.target.files[0])} />
              </div>
              <div>
                <h5>Privacy Policy (docx)</h5>
                <input type='file' name='privacy' id='privacy' onChange={e => setPolicyFile(e.target.files[0])} />
              </div>
            </div>
            <div>
              <input type='submit' value='Add' disabled={!termsFile || !policyFile || uploadLoading} />
            </div>
            {uploadError && <div style={{ color: 'red' }}>{uploadError}</div>}
          </form>
        </section>
        <section className='policy-terms-container'>
          {fetchTermsError && <div style={{ color: 'red' }}>{fetchTermsError}</div>}
          {terms?.termsAgreement && <div>{parse(terms.termsAgreement.content)}</div>}
          {terms?.agreementPolicy && <div>{parse(terms.agreementPolicy.content)}</div>}
        </section>
      </div>
    </BaseScreen>
  )
}

export default PrivacyTermsScreen
