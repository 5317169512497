import { useSelector } from 'react-redux'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { baseRoute, downloadsTrackingRoute, loginRoute, routes } from './API/routes'
import './App.css'

function App() {
  const { userId } = useSelector(state => state.login)
  const deafultBaseRoute =
    routes.find(route => route.route === (userId ? downloadsTrackingRoute : loginRoute)).route ?? baseRoute

  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          {routes.map(route => (
            <Route path={route.route} element={route.element} key={route.route + route.text} />
          ))}
          <Route path='*' element={<Navigate to={deafultBaseRoute} replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
