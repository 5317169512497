import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InputSearch from '../../components/InputSearch/InputSearch'
import ReportsDownloadsTable from '../../components/ReportsDownloadsTable/ReportsDownloadsTable'
import ReportsPrefsTable from '../../components/ReportsPrefsTable/ReportsPrefsTable'
import BaseScreen from '../../Containers/BaseScreen/BaseScreen'
import useDebounce from '../../hooks/useDebounce'
import { getUserDownloads, getUserDownloadsStats, getUserReports } from '../../store/actions/downloadsActions'
import { DOWNLOADS_STATS_CLEAR, USER_DOWNLOADS_CLEAR, USER_REPORTS_CLEAR } from '../../store/actionTypes'
import './DownloadsManagementScreen.scss'

const DownloadsManagementScreen = () => {
  const dispatch = useDispatch()
  const { userReports, month, userDownloads } = useSelector(state => state.downloads)
  const [agency, setAgency] = useState('')
  const [openForAll, setOpenForAll] = useState(false)
  const [currTable, setCurrTable] = useState(tableType.downloads)

  const debouncedAgency = useDebounce(agency.trim())

  const tables = [
    {
      id: 1,
      type: tableType.downloads,
      setActive: () => setCurrTable(tableType.downloads),
      text: 'Agencies Reports Downloads',
    },
    {
      id: 2,
      type: tableType.preferences,
      setActive: () => setCurrTable(tableType.preferences),
      text: 'Agencies Reports Preferences',
    },
  ]

  const openForAllHandler = () => setOpenForAll(!openForAll)

  const renderTable = () => (currTable === tableType.preferences ? <ReportsPrefsTable /> : <ReportsDownloadsTable />)

  useEffect(() => {
    if (currTable === tableType.preferences) {
      if (debouncedAgency.length > 0) {
        dispatch(getUserReports(debouncedAgency))
      } else if (userReports && userReports.length > 0) {
        dispatch({ type: USER_REPORTS_CLEAR })
      }
    } else {
      dispatch(getUserDownloads(debouncedAgency, new Date(month)))
      dispatch(getUserDownloadsStats(debouncedAgency, new Date(month)))
    }
  }, [dispatch, debouncedAgency, currTable, month])

  return (
    <BaseScreen header={'Report Downloads Management'}>
      <div className='downloads-management-screen'>
        <div className='downloads-management-controls'>
          <div className='downloads-management-open-all'>
            <button onClick={openForAllHandler}>{`${openForAll ? 'Close' : 'Open'} Downloads for All Users`}</button>
          </div>
          <div className='downloads-management-user-search-container'>
            <div className='downloads-management-user-search'>
              <InputSearch placeholder='Search Agency' value={agency} onChange={value => setAgency(value)} />
            </div>
          </div>
        </div>
        <div className='downloads-management-navbar-wrapper'>
          <nav>
            <ul>
              {tables.map(t => (
                <li key={t.id} className={classNames({ 'active-tab': currTable === t.type })} onClick={t.setActive}>
                  {t.text}
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className='downloads-management-tables-wrapper'>{renderTable()}</div>
      </div>
    </BaseScreen>
  )
}

export default DownloadsManagementScreen

const tableType = {
  preferences: 'preferences',
  downloads: 'downloads',
}
