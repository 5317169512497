import classNames from 'classnames'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SET_MONTH } from '../../store/actionTypes'
import { insuranceField } from '../../types/insuranceField'
import { reportRecordStatusType } from '../../types/reportRecordStatus'
import { getInputDateMonth, inputCurrDate, inputPrevYearDate } from '../../utils/date'
import { percentage } from '../../utils/number'
import ModalReportStatusDetails from '../ModalReportStatusDetails/ModalReportStatusDetails'
import './ReportsDownloadsTable.scss'

const ReportsDownloadsTable = () => {
  const dispatch = useDispatch()
  const { userDownloads, userDownloadsLoading, month, userStats } = useSelector(state => state.downloads)
  const [report, setReport] = useState(null)
  const pages = 0

  const setMonthHandler = e => dispatch({ type: SET_MONTH, payload: getInputDateMonth(e.target.value) })

  const setReportHandler = (report, reportStatuses) => {
    setReport({ ...report, statuses: reportStatuses, month })
  }

  const closeModalHandler = () => {
    setReport(null)
  }

  if (userDownloadsLoading) {
    return <div className='reports-prefs-table-placeholder'>Loading...</div>
  }

  if (!userDownloads) {
    return <div className='reports-prefs-table-placeholder'>Search for a user to see data...</div>
  }

  return (
    <>
      <div className='reports-dwnlds-stats-container'>
        <div className='reports-dwnlds-stats-month'>
          <label htmlFor='monthInput'>Month</label>
          <input
            type='month'
            id='monthInput'
            name='monthInput'
            value={month}
            max={inputCurrDate()}
            min={inputPrevYearDate()}
            onChange={setMonthHandler}
          />
        </div>
        <div className='reports-dwnlds-stats-card-container'>
          <div className='reports-dwnlds-stats-card'>
            <h4>Agencies attempted to download</h4>
            <h3>{userStats.downloadAttempted}</h3>
          </div>
        </div>
        <div className='reports-dwnlds-stats-card-container'>
          <div className='reports-dwnlds-stats-card'>
            <h4>Agencies pending to download</h4>
            <h3>{userStats.downloadNotAttempted}</h3>
          </div>
        </div>
      </div>
      {userDownloadsLoading && <div className='reports-prefs-table-placeholder'>Loading...</div>}
      {!userDownloads && <div className='reports-prefs-table-placeholder'>Search for a user to see data...</div>}
      {!userDownloadsLoading && userDownloads && userDownloads.length > 0 && (
        <div className='reports-dwnlds-table-container'>
          <table>
            <thead>
              <tr>
                <th>Company</th>
                <th>Report Name</th>
                <th>Report Number</th>
                <th>Field</th>
                <th>Successfully Downloaded</th>
                <th>Scan Error (30)</th>
                <th>Report Error (55/60/65)</th>
                <th>Not Yet Downloaded (10)</th>
              </tr>
            </thead>
            <tbody>
              {userDownloads.map((r, i) => {
                const totalAgenciesCount =
                  r.downloadedReports + r.unknownScanErrorReports + r.downloadedWithErrorsReports + r.notTouchedReports
                return (
                  <tr key={r.reportName + i}>
                    <td>{r.companyName}</td>
                    <td>{r.reportName}</td>
                    <td>{r.reportNumber}</td>
                    <td>{insuranceField[r.fieldId]?.nameEn}</td>
                    <td
                      {...(r.downloadedReports > 0 && {
                        onClick: () => setReportHandler(r, [reportRecordStatusType.saved]),
                      })}
                      className={classNames({
                        green: percentage(r.downloadedReports, totalAgenciesCount) > 90,
                        clickable: r.downloadedReports > 0,
                      })}
                    >
                      {r.downloadedReports}
                    </td>
                    <td
                      {...(r.unknownScanErrorReports > 0 && {
                        onClick: () => setReportHandler(r, [reportRecordStatusType.unknownScanError]),
                      })}
                      className={classNames({
                        red: percentage(r.unknownScanErrorReports, totalAgenciesCount) > 5,
                        clickable: r.unknownScanErrorReports > 0,
                      })}
                    >
                      {r.unknownScanErrorReports}
                    </td>
                    <td
                      {...(r.downloadedWithErrorsReports > 0 && {
                        onClick: () =>
                          setReportHandler(r, [
                            reportRecordStatusType.saveFailed,
                            reportRecordStatusType.noData,
                            reportRecordStatusType.reportNotFound,
                          ]),
                      })}
                      className={classNames({
                        orange: percentage(r.downloadedWithErrorsReports, totalAgenciesCount) > 5,
                        clickable: r.downloadedWithErrorsReports > 0,
                      })}
                    >
                      {r.downloadedWithErrorsReports}
                    </td>
                    <td
                      {...(r.notTouchedReports > 0 && {
                        onClick: () => setReportHandler(r, [reportRecordStatusType.none]),
                      })}
                      className={classNames({
                        yellow: percentage(r.notTouchedReports, totalAgenciesCount) > 50,
                        clickable: r.notTouchedReports > 0,
                      })}
                    >
                      {r.notTouchedReports}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
      {report && <ModalReportStatusDetails closeModal={closeModalHandler} report={report} />}
      {pages > 0 && <div className='reports-dwnlds-table-paging'>Pages</div>}
    </>
  )
}

export default ReportsDownloadsTable
