import instance from '../http'
import { urlAgencies } from '../urls'

export const getAgenciesRequest = async ({
  companiesIds = [],
  agencyName = '',
  agencyDbName = '',
  registrationNumber = null,
}) => {
  const {
    data: { data: agencies },
  } = await instance({
    url: `${urlAgencies()}get-agencies`,
    method: 'POST',
    data: { companiesIds, agencyName, agencyDbName, registrationNumber },
  })
  return agencies
}

export const getDeletedAgenciesRequest = async () => await instance.get(`${urlAgencies()}deleted-agencies`)

export const removeAgencyRequest = async agencyId => await instance.put(`${urlAgencies()}remove-agency/${agencyId}`)

export const restoreAgencyRequest = async agencyName =>
  await instance.put(`${urlAgencies()}restore-agency/${agencyName}`)
