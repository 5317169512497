import { loginRequest } from '../../API/http'
import { removeItemFromLocalStorage } from '../../services/localStorageService'
import { LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT } from '../actionTypes'

export const login = (email, password) => async dispatch => {
  dispatch({ type: LOGIN_REQUEST })
  try {
    const data = await loginRequest(email, password)
    dispatch({
      type: LOGIN_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload: error.message,
    })
  }
}

export const logout = () => {
  removeItemFromLocalStorage('accessToken')
  removeItemFromLocalStorage('refreshToken')
  removeItemFromLocalStorage('refreshTokenExpireAt')
  removeItemFromLocalStorage('userId')
  removeItemFromLocalStorage('id')
  return {
    type: LOGOUT,
  }
}
