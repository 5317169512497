import { getAgenciesRequest } from '../../API/requests/agenciesRequests'
import { AGENCIES_FAIL, AGENCIES_LOADING, AGENCIES_SUCCESS } from '../actionTypes'

export const getAgencies =
  ({ companiesIds = [], agencyName = '', agencyDbName = '', registrationNumber = '', showLoading = true } = {}) =>
  async dispatch => {
    dispatch({ type: AGENCIES_LOADING, payload: showLoading })
    try {
      const data = await getAgenciesRequest(
        ({ companiesIds = [], agencyName = '', agencyDbName = '', registrationNumber = '' } = {})
      )
      dispatch({
        type: AGENCIES_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: AGENCIES_FAIL,
        payload: true,
      })
    }
  }
